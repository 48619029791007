import React, { useEffect, useMemo } from 'react';
import {
    AppBar,
    Card,
    CardContent,
    Toolbar,
    Typography,
    Grid,
    Container
} from '@material-ui/core';

import {
    Link
} from 'react-router-dom';

import NewIcon from '@material-ui/icons/AddBox';
import ListIcon from '@material-ui/icons/Storage'
import CheckIcon from '@material-ui/icons/CheckBox';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

import useStyles from "../../styles/Main";
import { useAuth } from '../../AuthContext';

import PessoaService from '../../services/Pessoa';

export default function Dashboard() {
    const classes = useStyles();
    const { user, logout } = useAuth();
    const PessoaModel = useMemo(() => new PessoaService(user.id, user.token), [user]);

    const handleClickLogout = (event) => {
        logout();
    }

    useEffect(() => {
        if (user) {
            PessoaModel.retrieve(user.id);
        }

    }, [PessoaModel, user])

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="50" />
                    <Typography variant="body1">
                        {user.nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" className={classes.dashboard}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Link to="/solicitar" className={classes.link}>
                            <Card>
                                <CardContent align="center" className={classes.menuButton}>
                                    <NewIcon style={{ fontSize: 80 }} />
                                    <br /><br />
                                    <Typography variant="body1">
                                        Nova Solicitação
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link to="/os" className={classes.link}>
                            <Card>
                                <CardContent align="center" className={classes.menuButton}>
                                    <ListIcon style={{ fontSize: 80 }} />
                                    <br /><br />
                                    <Typography variant="body1">
                                        Ordens de Serviço
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link to="/protocolos" className={classes.link}>
                            <Card>
                                <CardContent align="center" className={classes.menuButton}>
                                    <CheckIcon style={{ fontSize: 80 }} />
                                    <br /><br />
                                    <Typography variant="body1">
                                        Protocolos
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link to="/financeiro" className={classes.link}>
                            <Card>
                                <CardContent align="center" className={classes.menuButton}>
                                    <MoneyIcon style={{ fontSize: 80 }} />
                                    <br /><br />
                                    <Typography variant="body1">
                                        Financeiro
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Card onClick={handleClickLogout}>
                            <CardContent align="center" style={{
                                marginTop: '6px',
                                cursor: 'pointer',
                                color: '#007cc3'
                            }}>
                                <Typography variant="body1">
                                    Sair
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}