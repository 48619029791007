import React from 'react';
import { ThemeProvider } from "@material-ui/core/styles";
import { AuthProvider } from './AuthContext';
import Routes from './Routes';
import theme from './styles/global';
import { CssBaseline } from '@material-ui/core';

const App = () => {
    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        </AuthProvider>
    )
}

export default App;