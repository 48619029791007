export default class ProtocoloServico {

    constructor(id, token) {
        this.id = id;
        this.token = token;
    }

    list(page = 0, size = 30, filters = '') {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("token", this.token);

            const url = process.env.REACT_APP_BASE_URL_API + '/api/protocolos?cliente='+this.id+'&page=' + (page + 1) + '&size=' + size + filters;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}