import React, { useState, useMemo } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Divider,
    Grid,
    IconButton,
    Paper,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Button,
    Snackbar
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import { Link } from 'react-router-dom';

import Loading from '../../components/Loading';

import ArrowLeftIcon from '@material-ui/icons/ArrowBackIos';

import { DropzoneAreaBase } from 'material-ui-dropzone';

import useStyles from "../../styles/Main";
import { useAuth } from '../../AuthContext';

import SolicitacaoService from '../../services/Solicitacao';

export default function Solicitar() {
    const classes = useStyles();
    const { user } = useAuth();
    const SolicitacaoModel = useMemo(() => new SolicitacaoService(user.id, user.token), [user]);

    const [data, setData] = useState({
        cliente: user.id,
        tipo: '0',
        resumo: '',
        anexos: []
    });

    const [loading, setLoading] = useState(false);

    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })

    const handleChangeField = (event) => {
        const updateData = { ...data };
        updateData[event.target.name] = event.target.value;
        setData(updateData);
    };

    const handleAddFile = (files) => {
        const updateData = { ...data };
        updateData.anexos = updateData.anexos.concat(files);
        setData(updateData);
    }

    const handleDeleteFile = (file) => {
        const updateData = { ...data };
        updateData.anexos.splice(updateData.anexos.findIndex(x => x.file.name === file.file.name), 1);
        setData(updateData);
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleSave = (event) => {
        event.preventDefault();

        setLoading(true);

        SolicitacaoModel.create(data).then(() => {
            setLoading(false);
            setResponseStatus({
                open: true,
                severity: "success",
                message: "Solicitação enviada com sucesso"
            });
            setTimeout(() => {
                window.location.reload();
            },3000)
        }).catch(error => {
            setLoading(false);
            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
            });
        })
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="50" style={{ verticalAlign: 'middle' }} />
                    </Link>
                    <Typography variant="body1">
                        {user.nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant="h6" className={classes.title}>
                    <Link to="/">
                        <IconButton size="small">
                            <ArrowLeftIcon />
                        </IconButton>
                    </Link>
                    Nova Solicitação
                </Typography>
                <Divider />
                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <form onSubmit={handleSave}>
                        <Paper square style={{ padding: '20px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel
                                            shrink
                                            id="tipo-label"
                                            style={{
                                                background: 'white',
                                                paddingLeft: '3px',
                                                paddingRight: '5px'
                                            }}>
                                            Qual é o serviço
                                        </InputLabel>
                                        <Select
                                            name="tipo"
                                            labelid="tipo-label"
                                            size="small"
                                            value={data?.tipo}
                                            onChange={handleChangeField}
                                        >
                                            <MenuItem key="0" value="0">Topografia</MenuItem>
                                            <MenuItem key="1" value="1">Engenharia/Arquitetura</MenuItem>
                                            <MenuItem key="2" value="2">Regularização de Imóvel</MenuItem>
                                            <MenuItem key="3" value="3">Não sei, preciso da ajuda de um especialista</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel
                                            shrink
                                            id="tipo-label"
                                            style={{
                                                background: 'white',
                                                paddingLeft: '3px',
                                                paddingRight: '5px'
                                            }}>
                                            Faça aqui uma breve descrição do que deseja
                                        </InputLabel>
                                        <TextField
                                            name="resumo"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            value={data?.resumo}
                                            onChange={handleChangeField}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <DropzoneAreaBase
                                        filesLimit={10}
                                        fileObjects={data?.anexos}
                                        dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                                        maxFileSize={300000000}
                                        onAdd={handleAddFile}
                                        onDelete={handleDeleteFile}
                                        showFileNames={true}
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        previewText="Arquivos selecionados"
                                        showAlerts={['error']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                    >
                                        Enviar Solicitação
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                </Container>
                <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                    <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                        {responseStatus.message}
                    </Alert>
                </Snackbar>
                <Loading open={loading} />
            </Container>
        </React.Fragment>
    )
}