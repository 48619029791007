import React from 'react';
// import { 
//     TableContainer, 
//     Table, 
//     TableHead, 
//     TableBody, 
//     TableRow, 
//     TableHeaderCell, 
//     TableCell, 
//     TablePagination
// } from './styles';

import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TablePagination,
    Paper
} from '@material-ui/core'

function DataTable(props) {
    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow key="datatable-header">
                            {props.columns.map((column, index) => (
                                <TableCell key={column.id+'-'+index} align={column.align}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.docs?.length > 0 && props.docs?.map((doc, index) => (
                            <TableRow key={doc._id+"-"+index} hover>
                                {props.columns.map((column) => {
                                    let value;

                                    if (column.type === 'hybrid') {
                                        value = doc;
                                    } else {
                                        value = doc[column.id];
                                    }
                                    
                                    return (
                                        <TableCell key={doc._id+"-"+index+"-"+column.id} align={column.align} style={{minWidth: column.width+'px'}}>
                                            {column.format ? column.format(value, index) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {(!props.docs || props.docs.length <= 0) && (
                            <TableRow>
                                <TableCell colSpan={props.columns.length} align="center">
                                    Não existem registros para serem exibidos
                                </TableCell>
                            </TableRow>
                        )}
                        {props.footer}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.count > 0 &&
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    component="div"
                    count={props.count}
                    rowsPerPage={props.size}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeSize}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    labelRowsPerPage="Registros por página"
                    nextIconButtonText="Próxima página"
                    backIconButtonText="Página anterior"
                />
            )}
        </Paper>
    )
}

export default DataTable;