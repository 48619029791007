export default class Pessoa {

    constructor(id, token) {
        this.id = id;
        this.token = token;
    }

    retrieve(id) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("token", this.token);

            const url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/' + id;
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(url, options)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            localStorage.removeItem('@stkCliente/user');
                            window.location.reload();
                        } else {
                            return response.json();
                        }
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    login(user) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");

            const url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/login';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(user)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}