import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    CircularProgress
} from '@material-ui/core';

import moment from 'moment';

import { useAuth } from '../../AuthContext';
import OrdemServicoService from '../../services/OrdemServico';

function Mensagens(props) {
    const { user } = useAuth();
    const OrdemServicoModel = useMemo(() => new OrdemServicoService(user.id, user.token), [user])
    const [mensagens, setMensagens] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.data) {
            setLoading(true);
            OrdemServicoModel.retrieve(props.data).then(response => {
                let agruparMensagens = [];

                for (let index = 0; index < response.servicos.length; index++) {
                    const servico = response.servicos[index];

                    if (servico.mensagens && servico.mensagens.length > 0) {
                        for (let nX = 0; nX < servico.mensagens.length; nX++) {
                            const mensagem = servico.mensagens[nX];
                            if (mensagem.exibeCliente) agruparMensagens.push(mensagem);
                        }
                    }
                }

                agruparMensagens = agruparMensagens.sort((a, b) => {
                    return a.data > b.data ? -1 : 1
                })

                setMensagens(agruparMensagens);
                setLoading(false);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [props.data, OrdemServicoModel])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Mensagens</DialogTitle>
            <DialogContent align="center">
                {loading && <CircularProgress />}
                {!loading &&
                    <List>
                        {mensagens && mensagens.map((mensagem, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemText
                                        align="left"
                                        primary={mensagem.mensagem}
                                        secondary={mensagem.responsavel + ' (' + moment(mensagem.data).format("DD/MM/YYYY - HH:mm") + ')'}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.confirm} color="primary" autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Mensagens;