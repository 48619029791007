export default class SolicitacaoServico {

    constructor(id, token) {
        this.id = id;
        this.token = token;
    }

    create(solicitacao) {
        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            headers.append("token", this.token);

            const url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes';
            const options = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(solicitacao)
            };

            fetch(url, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then(err => { throw err });
                    }
                })
                .then(data => {
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
        })
    }
}