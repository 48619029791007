import React, { createContext, useContext, useState, useEffect } from 'react';
import PessoaService from './services/Pessoa';

const AuthContext = createContext({
	authenticated: true,
	user: null,
	loading: false,
	login: () => {},
	logout: () => {}
});

const AuthProvider = (props) => {
	const PessoaModel = new PessoaService();
	const [user, setUser] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function loadStorageData() {
			const storedUser = JSON.parse(localStorage.getItem("@stkCliente/user"));

			if (storedUser) {
				setUser(storedUser);
				setIsLoggedIn(true);
	
			}

			setLoading(false);
		}

		loadStorageData();
	}, [])

	function login(user) {
		return new Promise((resolve, reject) => {
			setLoading(true);
			PessoaModel.login(user).then(res => {
				localStorage.setItem("@stkCliente/user", JSON.stringify(res));
				setUser(res);
				setIsLoggedIn(true);
				setLoading(false);
				console.log(res);
				resolve();
			}).catch(error => {
				setLoading(false);
				reject(error);
			})
		})
	}

	function logout() {
		setIsLoggedIn(false);
		setUser(null);
		localStorage.removeItem("@stkCliente/user");
	}

	return (
		<AuthContext.Provider value={{ authenticated: isLoggedIn , user, loading, login, logout }}>
			{props.children}
		</AuthContext.Provider>
	)
}

function useAuth() {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider.');
	}

	return context;
}

export { AuthProvider, useAuth };