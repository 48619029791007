import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Divider,
    Grid,
    IconButton,
    Box,
    useTheme
} from '@material-ui/core';

import Loading from '../../components/Loading';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';

import ArrowLeftIcon from '@material-ui/icons/ArrowBackIos';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import useStyles from "../../styles/Main";
import { useAuth } from '../../AuthContext';

import ProtocoloService from '../../services/Protocolo';
import { Link } from 'react-router-dom';

export default function Protocolo() {
    const theme = useTheme();
    const classes = useStyles();
    const { user } = useAuth();
    const ProtocoloModel = useMemo(() => new ProtocoloService(user.id, user.token), [user])

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        protocolDateFrom: null,
        protocolDateTo: null,
        finishDateFrom: null,
        finishDateTo: null,
        status: []
    })

    const Listar = useCallback((page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        ProtocoloModel.list(page, size, filter).then(response => {
            setData({
                isLoading: false,
                docs: response.docs,
                page: response.page - 1,
                size: response.limit,
                count: response.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log("error", error);
        });
    }, [ProtocoloModel])

    useEffect(() => {
        Listar(data.page, data.size, data.filters);
    }, [Listar, data.page, data.size, data.filters]);

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleKeyUpSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][cliente.nome][$regex]=" + filters.search;
            qs += "&$or[0][cliente.nome][$options]=gi";
            qs += "&$or[1][numero][$regex]=" + filters.search;
            qs += "&$or[1][numero][$options]=gi";
        }

        if (filters.dateFrom) qs += '&dataSolicitacao[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&dataSolicitacao[$lte]=' + filters.dateTo;
        if (filters.protocolDateFrom) qs += '&dataProtocolo[$gte]=' + filters.protocolDateFrom;
        if (filters.protocolDateTo) qs += '&dataProtocolo[$lte]=' + filters.protocolDateTo;
        if (filters.dueDateFrom) qs += '&dataPrazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&dataPrazo[$lte]=' + filters.dueDateTo;
        if (filters.finishDateFrom) qs += '&dataConclusao[$gte]=' + filters.finishDateFrom;
        if (filters.finishDateTo) qs += '&dataConclusao[$lte]=' + filters.finishDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            protocolDateFrom: null,
            protocolDateTo: null,
            finishDateFrom: null,
            finishDateTo: null,
            status: []
        });

        setData({ ...data, filters: '' })
    }

    const columns = [
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'dataSolicitacao',
            label: 'Data Solicitação',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataProtocolo',
            label: 'Data Protocolo',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataPrazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataConclusao',
            label: 'Data Conclusão',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (doc.dataConclusao ? new Date(doc.dataConclusao).toLocaleDateString('pt-BR') : '')
        },
        {
            id: 'resumo',
            label: 'Resumo',
            align: 'left',
            format: (value) =>  <span style={{whiteSpace: 'pre-line'}}>{value}</span>
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'left',
            width: 150,
            format: (value) => (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {value === "Aberto" && <DotIcon color="secondary" />}
                    {value === "Concluído" && <DotIcon style={{ color: theme.palette.success.main }} />}
                    <span>{value}</span>
                </Box>
            )
        }
    ];

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data Solicitação de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data Solicitação até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'protocolDateFrom',
            name: 'protocolDateFrom',
            label: 'Data Protocolo de',
            value: filters.protocolDateFrom,
            onChange: (value) => { setFilters({ ...filters, protocolDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'protocolDateTo',
            name: 'protocolDateTo',
            label: 'Data Protocolo até',
            value: filters.protocolDateTo,
            onChange: (value) => { setFilters({ ...filters, protocolDateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'date',
            id: 'finishDateFrom',
            name: 'finishDateFrom',
            label: 'Data Conclusão de',
            value: filters.finishDateFrom,
            onChange: (value) => { setFilters({ ...filters, finishDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'finishDateTo',
            name: 'finishDateTo',
            label: 'Data Conclusão até',
            value: filters.finishDateTo,
            onChange: (value) => { setFilters({ ...filters, finishDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'status',
            name: 'status',
            label: 'Situação',
            value: filters.status,
            options: ['Aberto', 'Concluído'],
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="50" style={{ verticalAlign: 'middle' }} />
                    </Link>
                    <Typography variant="body1">
                        {user.nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            <Link to="/">
                                <IconButton size="small">
                                    <ArrowLeftIcon />
                                </IconButton>
                            </Link>
                            Protocolos
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FilterContainer
                            title="Filtros"
                            filters={filterOptions}
                            handleApplyFilters={handleApplyFilters}
                            handleDismissFilters={handleDismissFilters}
                        />
                    </Grid>
                </Grid>
                {!isLoading && docs ? (
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                ) : isLoading && <Loading open={isLoading} />}
            </Container>
        </React.Fragment>
    )
}