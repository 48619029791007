import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '3rem',
        height: '100%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: indigo[500],
        color: 'white',
        '&:hover': {
            backgroundColor: indigo[700],
        },
    },
}));

export default useStyles;