import React, { useState } from "react";
import {
    Button,
    TextField,
    Link,
    Box,
    Typography,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    Container
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import useStyles from "../styles/Login";

import { useAuth } from '../AuthContext';

function Login() {
    const { loading, login } = useAuth();
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [user, setUser] = useState({});

    const handleChangeField = (event) => {
        const updateUser = { ...user };
        updateUser[event.target.name] = event.target.value;
        setUser(updateUser);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        login(user).then(res => {
            //window.location.reload();
        }).catch(error => {
            setError('Senha inválida');
        });
    };

    return (
        <Container className={classes.container}>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={7} md={5} lg={4} xl={3}>
                    <Card>
                        <CardContent align="center" style={{ padding: '2rem' }}>
                            <form>
                                <img
                                    src={process.env.PUBLIC_URL + "/logo.png"}
                                    width={150}
                                    alt="logo"
                                />
                                <Typography variant="h6" style={{ marginTop: '10px' }}>
                                    Área do Cliente
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="senha"
                                    label="Senha de acesso"
                                    type="password"
                                    autoFocus
                                    autoComplete="current-password"
                                    onChange={handleChangeField}
                                />
                                {error && <Alert severity="error">{error}</Alert>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className={classes.submit}
                                    disabled={loading}
                                    onClick={handleLogin}
                                >
                                    Entrar
                                    {loading && <CircularProgress size={24} />}
                                </Button>
                                <Box pb={5}>
                                    <Typography variant="body2" align="center">
                                        {"Copyright © "}
                                        <Link href="https://www.compassio.com.br/" target="_blank">
                                            Compassio
                                        </Link>{" "}
                                        {new Date().getFullYear()}
                                    </Typography>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
};

export default Login;