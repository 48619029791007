import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Link,
    CircularProgress
} from '@material-ui/core';

//import { Link } from 'react-router-dom';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { useAuth } from '../../AuthContext';
import OrdemServicoService from '../../services/OrdemServico';

function Arquivos(props) {
    const { user } = useAuth();
    const OrdemServicoModel = useMemo(() => new OrdemServicoService(user.id, user.token), [user])
    const [arquivos, setArquivos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.data) {
            setLoading(true);
            OrdemServicoModel.retrieve(props.data).then(response => {
                let agruparArquivos = [];

                for (let index = 0; index < response.servicos.length; index++) {
                    const servico = response.servicos[index];

                    if (servico.anexos && servico.anexos.length > 0) {
                        for (let nX = 0; nX < servico.anexos.length; nX++) {
                            const anexo = servico.anexos[nX];
                            if (anexo.exibeCliente) agruparArquivos.push(anexo);
                        }
                    }
                }

                agruparArquivos = agruparArquivos.sort((a, b) => {
                    return a.data > b.data ? -1 : 1
                })

                setArquivos(agruparArquivos);
                setLoading(false);

            }).catch(error => {
                console.log(error);
            })
        }
    }, [props.data, OrdemServicoModel])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Arquivos</DialogTitle>
            <DialogContent align="center">
                {loading && <CircularProgress />}
                {!loading &&
                    <List>
                        {arquivos && arquivos.map((arquivo, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemIcon style={{ maxWidth: '150px' }}>
                                        <DescriptionOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText align="left">
                                        <Link href={arquivo.url} target="_blank">
                                            {arquivo.descricao ? arquivo.descricao : arquivo.name}
                                        </Link>
                                    </ListItemText>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.confirm} color="primary" autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Arquivos;