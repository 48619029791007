import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Dashboard from "./pages/Dashboard";
import OrdemServico from "./pages/OrdemServico";
import Financeiro from "./pages/Financeiro";
import Protocolos from "./pages/Protocolos";
import Solicitar from "./pages/Solicitar";
import Login from "./pages/Login";

const Routes = () => {
	const { authenticated } = useAuth()

	return (
		authenticated ? (
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<Dashboard />
					</Route>
					<Route exact path="/os">
						<OrdemServico />
					</Route>
					<Route exact path="/protocolos">
						<Protocolos />
					</Route>
					<Route exact path="/financeiro">
						<Financeiro />
					</Route>
					<Route exact path="/solicitar">
						<Solicitar />
					</Route>

					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			</BrowserRouter >

		) : (
				<Login />
			)
	)
};
export default Routes;