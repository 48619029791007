import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Divider,
    Grid,
    IconButton,
    Box,
    useTheme
} from '@material-ui/core';

import Loading from '../../components/Loading';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';

import ArrowLeftIcon from '@material-ui/icons/ArrowBackIos';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import useStyles from "../../styles/Main";
import { useAuth } from '../../AuthContext';

import FinanceiroService from '../../services/Financeiro';
import { Link } from 'react-router-dom';

export default function Financeiro() {
    const theme = useTheme();
    const classes = useStyles();
    const { user } = useAuth();
    const FinanceiroModel = useMemo(() => new FinanceiroService(user.id, user.token), [user])

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [filters, setFilters] = useState({
        search: "",
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: ["Em aberto", "Em atraso","Baixado"],
    })

    const Listar = useCallback((page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        FinanceiroModel.list(page, size, filter).then(response => {
            setData({
                isLoading: false,
                docs: response.docs,
                page: response.page - 1,
                size: response.limit,
                count: response.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log("error", error);
        });
    }, [FinanceiroModel])

    useEffect(() => {
        Listar(data.page, data.size, data.filters);
    }, [Listar, data.page, data.size, data.filters]);

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleKeyUpSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
		let qs = "";

		if (filters.search) {
			qs += "&$or[0][numero]=" + filters.search;
			qs += "&$or[1][descricao][$regex]=" + filters.search;
			qs += "&$or[1][descricao][$options]=gi";
		}

		if (filters.dateFrom) qs += "&emissao[$gte]=" + filters.dateFrom;
		if (filters.dateTo) qs += "&emissao[$lte]=" + filters.dateTo;
		if (filters.dueDateFrom) qs += "&vencimento[$gte]=" + filters.dueDateFrom;
		if (filters.dueDateTo) qs += "&vencimento[$lte]=" + filters.dueDateTo;
		if (filters.status.length > 0)
			qs += "&situacao[$in]=" + filters.status.join();

		setData({
			...data,
			filters: qs,
		});
    }

    const handleDismissFilters = () => {
		document.getElementById("search").value = "";
		setFilters({
			search: "",
			dateFrom: null,
			dateTo: null,
			dueDateFrom: null,
			dueDateTo: null,
			status: ["Em aberto", "Em atraso","Baixado"],
		});

		setData({ ...data, filters: "&situacao[$in]=Em aberto,Em atraso,Baixado" });
    }

	const columns = [
		{
			id: "vencimento",
			label: "Vencimento",
			align: "center",
			format: (value) => new Date(value).toLocaleDateString("pt-BR"),
		},
		{
			id: "descricao",
			label: "Descrição",
			align: "left",
		},
		{
			id: "pessoa",
			label: "Cliente",
			align: "left",
			format: (pessoa) => pessoa?.nome,
		},
		{
			id: "numero",
			label: "Número",
			align: "center",
		},
		{
			id: "emissao",
			label: "Emissão",
			align: "center",
			format: (value) => (
				<>
					{new Date(value).toLocaleDateString("pt-BR")}
					<br />
					{new Date(value).toLocaleTimeString("pt-BR")}
				</>
			),
		},
		{
			id: "valor",
			label: "Valor Original",
			align: "right",
			format: (value) =>
				value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
		},
		{
			id: "saldo",
			label: "Saldo",
			align: "right",
			format: (value) =>
				value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
		},
		{
			id: "situacao",
			label: "Situação",
			align: "left",
			width: 150,
			format: (value) => (
				<Box display="flex" alignItems="center" flexWrap="wrap">
					{value === "Em aberto" && (
						<DotIcon style={{ color: theme.palette.warning.main }} />
					)}
					{value === "Em atraso" && <DotIcon color="secondary" />}
					{value === "Baixado" && (
						<DotIcon style={{ color: theme.palette.success.main }} />
					)}
					<span>{value}</span>
				</Box>
			),
		}
	];

	const filterOptions = [
		{
			type: "search",
			id: "search",
			name: "search",
			label: "Pesquisar",
			onKeyUp: handleKeyUpSearch,
			onBlur: (e) => {
				setFilters({ ...filters, search: e.target.value });
			},
		},
		{
			type: "date",
			id: "dateFrom",
			name: "dateFrom",
			label: "Emissão de",
			value: filters.dateFrom,
			onChange: (value) => {
				setFilters({
					...filters,
					dateFrom: value?.startOf("day").toISOString(),
				});
			},
		},
		{
			type: "date",
			id: "dateTo",
			name: "dateTo",
			label: "Emissão até",
			value: filters.dateTo,
			onChange: (value) => {
				setFilters({ ...filters, dateTo: value?.endOf("day").toISOString() });
			},
		},
		{
			type: "date",
			id: "dueDateFrom",
			name: "dueDateFrom",
			label: "Vencimento de",
			value: filters.dueDateFrom,
			onChange: (value) => {
				setFilters({
					...filters,
					dueDateFrom: value?.startOf("day").toISOString(),
				});
			},
		},
		{
			type: "date",
			id: "dueDateTo",
			name: "dueDateTo",
			label: "Vencimento até",
			value: filters.dueDateTo,
			onChange: (value) => {
				setFilters({
					...filters,
					dueDateTo: value?.endOf("day").toISOString(),
				});
			},
		},
		{
			type: "select",
			id: "status",
			name: "status",
			label: "Situação",
			value: filters.status,
			options: ["Em aberto", "Em atraso", "Baixado"],
			onChange: (e) => {
				setFilters({ ...filters, status: e.target.value });
			},
		},
	];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="50" style={{ verticalAlign: 'middle' }} />
                    </Link>
                    <Typography variant="body1">
                        {user.nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            <Link to="/">
                                <IconButton size="small">
                                    <ArrowLeftIcon />
                                </IconButton>
                            </Link>
                            Financeiro
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FilterContainer
                            title="Filtros"
                            filters={filterOptions}
                            handleApplyFilters={handleApplyFilters}
                            handleDismissFilters={handleDismissFilters}
                        />
                    </Grid>
                </Grid>
                {!isLoading && docs ? (
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                ) : isLoading && <Loading open={isLoading} />}
            </Container>
        </React.Fragment>
    )
}