import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme) => ({
    header: {
        background: '#007cc3',
    },
    toolbar: {
        justifyContent: 'space-between'
    },
    dashboard: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexGrow: '1',
            alignItems: 'center',
            height: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '5rem'
        }
    },
    menuButton: {
        margin: '20px',
        cursor: 'pointer',
        color: '#007cc3',
        '&:hover': {
            color: indigo[500]
        }
    },
    button: {
        color: 'white',
        background: '#007cc3',
        '&:hover': {
            background: indigo[500]
        }
    },
    link: {
        textDecoration: 'none'
    },
    container: {
        marginTop: '64px'
    },
    title: {
        paddingTop: '15px'
    }
}));

export default useStyles;