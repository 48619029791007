import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Divider,
    Grid,
    IconButton
} from '@material-ui/core';

import Loading from '../../components/Loading';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';

import Mensagens from './mensagens';
import Arquivos from './arquivos';

import VisibilityIcon from '@material-ui/icons/Visibility'
import DescriptionIcon from '@material-ui/icons/Description'
import ArrowLeftIcon from '@material-ui/icons/ArrowBackIos';

import useStyles from "../../styles/Main";
import { useAuth } from '../../AuthContext';

import OrdemServicoService from '../../services/OrdemServico';
import { Link } from 'react-router-dom';

export default function OrdemServico() {
    const classes = useStyles();
    const { user } = useAuth();
    const OrdemServicoModel = useMemo(() => new OrdemServicoService(user.id, user.token), [user])

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });
    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: []
    });
    const [dialogMensagens, setDialogMensagens] = useState({
        open: false,
        data: null
    })
    const [dialogArquivos, setDialogArquivos] = useState({
        open: false,
        data: null
    })

    const Listar = useCallback((page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        OrdemServicoModel.list(page, size, filter).then(response => {
            setData({
                isLoading: false,
                docs: response.docs,
                page: response.page - 1,
                size: response.limit,
                count: response.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log(error);
        });
    }, [OrdemServicoModel])

    useEffect(() => {
        Listar(data.page, data.size, data.filters);
    }, [Listar, data.page, data.size, data.filters]);

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleKeyUpSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][numero]=" + filters.search;
            qs += "&$or[1][descricao][$regex]=" + filters.search;
            qs += "&$or[1][descricao][$options]=gi";
        }

        if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&prazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&prazo[$lte]=' + filters.dueDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            status: []
        });

        setData({ ...data, filters: '' })
    }

    const columns = [
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'imoveis',
            label: 'Imóveis',
            align: 'left',
            format: (imoveis) => (imoveis.map((imovel, index) => (
                <React.Fragment key={imovel.descricao + "-" + index}>
                    {imovel.descricao}
                    <br />
                </React.Fragment>
            )))
        },
        {
            id: 'data',
            label: 'Data',
            align: 'center',
            format: (value) => (value &&
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'prazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => (value &&
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'center'
        },
        {
            id: '_id',
            label: 'Ações',
            align: 'center',
            format: (id) => (
                <React.Fragment>
                    <IconButton
                        title="Mensagens"
                        color="primary"
                        onClick={() => {
                            setDialogMensagens({
                                open: true,
                                data: id
                            })
                        }}
                    >
                        <VisibilityIcon color="primary" />
                    </IconButton>
                    <IconButton
                        title="Arquivos"
                        color="primary"
                        onClick={() => {
                            setDialogArquivos({
                                open: true,
                                data: id
                            })
                        }}
                    >
                        <DescriptionIcon color="primary" />
                    </IconButton>
                </React.Fragment>
            )
        }
    ];

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyUpSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'status',
            name: 'status',
            label: 'Situação',
            value: filters.status,
            options: ['Orçamento', 'Confirmado', 'Concluído'],
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="50" style={{ verticalAlign: 'middle' }} />
                    </Link>
                    <Typography variant="body1">
                        {user.nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            <Link to="/">
                                <IconButton size="small">
                                    <ArrowLeftIcon />
                                </IconButton>
                            </Link>
                            Ordens de Serviço
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FilterContainer
                            title="Filtros"
                            filters={filterOptions}
                            handleApplyFilters={handleApplyFilters}
                            handleDismissFilters={handleDismissFilters}
                        />
                    </Grid>
                </Grid>
                {!isLoading && docs ? (
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                ) : isLoading && <Loading open={isLoading} />}
                <Mensagens
                    open={dialogMensagens.open}
                    data={dialogMensagens.data}
                    confirm={() => {
                        setDialogMensagens({
                            open: false,
                            data: null
                        })
                    }}
                    onClose={() => {
                        setDialogMensagens({
                            open: false,
                            data: null
                        })
                    }}
                />
                <Arquivos
                    open={dialogArquivos.open}
                    data={dialogArquivos.data}
                    confirm={() => {
                        setDialogArquivos({
                            open: false,
                            data: null
                        })
                    }}
                    onClose={() => {
                        setDialogArquivos({
                            open: false,
                            data: null
                        })
                    }}
                />
            </Container>
        </React.Fragment>
    )
}