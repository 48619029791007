import { createTheme } from "@material-ui/core/styles";

const customTheme = createTheme({
    typography: {
        fontFamily: '"Maven Pro", sans-serif !important',
        fontSize: 14,
        color: '#333'
    },
    overrides: {
        MuiContainer: {
            root: {
                position: 'absolute',
                left: '0px',
                right: '0px'
            }
        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: '100%',
                },
                body: {
                    backgroundColor: '#ecf1f8',
                    height: '100%'
                },
                ul: {
                    listStyle: 'none'
                }
            },
        },
    }
});

export default customTheme;